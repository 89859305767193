.stop-popup-rct-component {
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 150px;
  padding-right: 10px;
}

.stop-popup-rct-component-headsign {
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 150px;
  padding-right: 5px;
}

.stop-popup-rct-component .stop-info p {
  margin: 0px;
}
.stop-popup-rct-component-headsign .stop-info p {
  margin: 0px;
}

.stop-popup-rct-component .stop-info h3 {
  margin: 0px;
  margin-bottom: 6px;
}

.stop-popup-rct-component-headsign .stop-info h3 {
  margin: 0px;
  margin-bottom: 6px;
}
.stop-popup-rct-component .routes {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  margin: 0 -4px;
}

.stop-popup-rct-component-headsign .routes {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  margin: 0 -4px;
}

.stop-popup-rct-component-headsign .arrival-row {
  display: flex;
  flex-direction: row;
  padding: 2px 0px 0px 0px;
  align-items: center;
  padding-bottom: 10px;
  height: 50px;
}

.stop-popup-rct-component .routes .stop-badge {
  flex: 1 1 auto;
}

.stop-popup-rct-component-headsign .routes .stop-badge {
  flex: 1 1 auto;
}

.stop-popup-rct-component-headsign .stop-badge {
    margin: 2px 4px;
   border-radius: 4px;
   text-align: center;
   padding: 3px;
   color: white;
   font-weight: bold;
   min-width: 30px;
} 

.stop-badge {
  margin: 2px 4px;
  border-radius: 4px;
  text-align: center;
  padding: 3px;
  color: white;
  font-weight: bold;
  min-width: 25px;
}

.arrivals-section {
  border-top: 1px solid grey;
}


.arrivals {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 15px;
  -webkit-overflow-scrolling: touch;
}
.arrivals p {
  margin-top: 0;
}
.arrivals .stop-badge {
  max-width: 50%;
}

.next-arrivals-text {
  font-weight: bold;
}


.arrival-row {
  display: flex;
  flex-direction: row;
  padding: 2px 0px 0px 0px;
}


.stop-popup-rct-component-headsign .arrival {
  padding-right: 4px;
  min-width: 60px;
} 

.arrival {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 0px 0px 4px;
}


 .headsign {
  font-weight: bold;
  padding-left: 2px;
  height: 20px;
} 


.mapboxgl-popup-content {
  padding-top: 20px;
}

.mapboxgl-popup-close-button {
  font-size: 1.45em;
}
